import React, { FC, useState, useEffect, } from "react"

import {
  Offer,
} from "@assets/types"

import { pdcStore } from "@assets/store/pdcStore"

import './index.sass'

import { ReactComponent as Left_chevron_SVG } from '@assets/icons/left_chevron.svg'
import { ReactComponent as Right_chevron_SVG } from '@assets/icons/right_chevron.svg'

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

export const PromotionImageWithFallback: FC<Props> = ({ promotion, style, className, ...rest }) => {

  const [fallback_img, set_fallback_img] = useState<string | null>(null)

  const promotion_skus = pdcStore.use.get_promotion_skus(promotion?.id)

  const current_promotion_sku_index = pdcStore.use.get_current_promotion_sku_index(promotion?.id)

  const current_promotion_sku = pdcStore.use.get_current_promotion_sku(promotion?.id)

  useEffect(() => {
    set_fallback_img(
      current_promotion_sku ?
        (
          current_promotion_sku.urlImage1
          || window.parameters.glob_programLogo
        )
        :
        promotion?.image
        || (
          promotion?.propertiesMap?.image ?
            `${window.assetsCdnPrefix}${promotion?.propertiesMap?.image}`
            :
            window.parameters.glob_programLogo
        )
    )
  }, [promotion, current_promotion_sku])

  return (
    <div className={`PromotionImageWithFallback ${className ?? ''}`}>
      {
        current_promotion_sku ?
          <Left_chevron_SVG onClick={() => {
            pdcStore.set.switch_current_promotion_sku_index(promotion?.id, -1)
          }} /> : <></>
      }

      <a
        style={{
          ...style ?? {},
          ...fallback_img ? {
            backgroundImage: `url(${fallback_img})`
          } : {}
        }}
        {...rest}
        className={`PromotionImageWithFallback_image ${current_promotion_sku ? 'with_carousel' : ''}`}
      >
        {
          fallback_img ?
            <img
              src={fallback_img}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                set_fallback_img(window.parameters.glob_programLogo)
              }}
              style={{
                display: 'none'
              }}
            />
            : ''
        }

        {
          current_promotion_sku ?
            <div className="dots" onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}>
              {
                promotion_skus?.map(sku =>
                  <div className={`dot ${sku.skuId == current_promotion_sku.skuId ? 'active' : ''}`} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}></div>
                )
              }
            </div>
            : <></>
        }
      </a>

      {
        current_promotion_sku ?
          <Right_chevron_SVG onClick={() => {
            pdcStore.set.switch_current_promotion_sku_index(promotion?.id, 1)
          }} /> : <></>
      }
    </div>
  )
}
