import React, { FC, useState, useEffect, useRef, useMemo, useCallback, } from "react"

//@ts-ignore
import clone from 'just-clone'

import { format } from 'date-fns'

import { Button } from "react-materialize"

import { promotionCardTopTagContent } from '@assets/functions/promotionCardTopTagContent'

import {
  Offer,
  ecommerceCartProduct,
  SKU,
} from "@assets/types"

import { ReactComponent as LockerSVG } from "./locker.svg"

import { ReactComponent as AddSVG } from '@assets/icons/add.svg'
import { ReactComponent as RemoveSVG } from '@assets/icons/remove.svg'
import { ReactComponent as ScheduleSVG } from '@assets/icons/schedule.svg'

import './style.sass'

import { useLocation } from "react-router-dom"

import { QueryLink } from '@assets/components/QueryLink'

import { PromotionDiscountTag } from '@assets/components/PromotionDiscountTag'

import { PromotionImageWithFallback } from '@assets/components/PromotionImageWithFallback'

import { PromotionPrices } from '@assets/components/PromotionPrices'

import { PromotionTypeTag } from '@assets/components/PromotionTypeTag'

import { PromotionSeals } from '@assets/components/PromotionSeals'

import { CashbackSeal } from '@assets/components/CashbackSeal'

import { useDebounceCallback } from 'usehooks-ts'

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import {
  parseDate,
  toast,
  promotionEvent,
  promotionHasSeals,
  promotionEventWithButtonText,
} from '@assets/utils'

import {
  actions,
  useTrackedStore,
  store,
  useStore,
} from '@assets/store'

import { pdcStore } from "@assets/store/pdcStore"

import { user_retail_skus } from '@assets/API/sku/user_retail_skus'

interface Props {
  item: Offer;
  cta?: boolean;
  style?: any;
  numberInList?: number;
  observerRef?: any;
}

export const PromotionCard: FC<Props> = ({ item, cta = false, style, numberInList, observerRef }) => {

  const inclusion_products = useMemo(() => item?.properties?.PRODUCT_ID_INCLUSION?.split(','), [item])

  useEffect(() => {
    if (inclusion_products && inclusion_products.length > 1) {
      (async () => {
        if (
          window.inIframe
          && window.currentTemplate == 'portal-do-cliente'
          && window.parameters.enable_promotion_multiple_skus
        ) {

          const skus = await user_retail_skus(item?.properties?.PRODUCT_ID_INCLUSION?.replaceAll(' ', ''))

          pdcStore.set.sku_add(item.id, skus.items.map((sku: SKU) => {
            const { name, urlImage1, skuId } = sku
            return { name, urlImage1, skuId }
          }))
        }
      })()
    }
  }, [item, inclusion_products])

  const promotion_skus = pdcStore.use.get_promotion_skus(item.id)

  const current_promotion_sku = pdcStore.use.get_current_promotion_sku(item.id)

  useEffect(() => {
    //console.log('promotion sku : ', promotion_skus)
    if (promotion_skus) {
      pdcStore.set.set_current_promotion_sku_index(item.id, 0)
    }
  }, [promotion_skus])

  const customer = useTrackedStore().app.customer()

  const registrationRedirectLink = useTrackedStore().app.registrationRedirectLink()

  const ecommerceCart = pdcStore.use.ecommerceCart()

  const { search } = useLocation()

  const productId = useMemo(() => {
    //console.log('current_promotion_sku  : ', current_promotion_sku)
    return current_promotion_sku?.skuId || item.properties?.PRODUCT_ID_INCLUSION?.split(',')[0]
  }, [current_promotion_sku])

  const max_available_quantity = useMemo(() => {
    if (productId) {

      return current_promotion_sku ?
        Math.max(
          ecommerceCart.filter(
            i => inclusion_products?.includes(i.productId) && productId != i.productId
          ).reduce(
            (a, c) => a - c.quantity,
            Math.min(item.remaining, item.maxPerCustomer)
          ),
          0
        )
        : Math.max(Math.min(item.remaining, item.maxPerCustomer), 0)
    }
  }, [ecommerceCart, inclusion_products, productId, current_promotion_sku])

  const changeQuantity = useCallback((quantity: number) => {

    if (productId && max_available_quantity != undefined) {
      const updatedCart = clone(ecommerceCart)

      let product = updatedCart.find(
        (i: ecommerceCartProduct) => i.productId == productId
      )

      if (!product) {
        product = {
          productId,
          quantity: 0
        }

        updatedCart.push(product)
      }

      product.quantity += quantity

      product.quantity = Math.max(Math.min(product.quantity, max_available_quantity), 0)

      pdcStore.set.ecommerceCart(updatedCart)
    }
  }, [ecommerceCart, productId, max_available_quantity])

  const promotionEndsToday = useMemo(() => {
    return (
      (
        item?.endDate ? new Date(item.endDate).getTime() : new Date().getTime()
      ) - new Date().getTime() < 86400 * 1000)
  }, [])

  const productInCart = useMemo(() => {
    return ecommerceCart?.find(p => productId == p.productId)
  }, [ecommerceCart, productId])

  const can_add_more = useMemo(
    () => (productInCart?.quantity || 0) < (max_available_quantity || 0),
    [
      productInCart,
      current_promotion_sku,
      max_available_quantity,
    ]
  )

  //this is checking if description is too long and applies resize styles  
  const [descOverflowing, setDescOverflowing] = useState(false)

  const descRef = useRef<HTMLDivElement>(null)

  const mainCardRef = useCallback(node => {
    if (node != null) {

      if (observerRef) {
        observerRef(node.parentElement)
      }

      node.addEventListener('mouseover', () => {
        let node = descRef.current

        let range = document.createRange()

        if (node?.firstChild) {
          range.selectNodeContents(node?.firstChild)
          let textRect = range.getBoundingClientRect()

          if (textRect.height > node.getBoundingClientRect().height + 5) {
            setDescOverflowing(true)
          }
        }
      }, { once: true })

    }
  }, [])

  const cardHasSeals = useMemo(() => promotionHasSeals(item), [
    item
  ])

  const conditions_click = useCallback(useDebounceCallback(
    (e) => {
      actions.app.OfferConditionsModalItem(item)
      actions.app.OfferConditionsModalOpened(true)

      promotionEcomEvent({
        event: 'offer_condition_link_click',
      }, item)
    }
  ), [item])

  const offerConditionsButton = useMemo(() => <Button
    flat
    className="white conditions-button"

    onClick={conditions_click}
  >
    Condições da oferta
  </Button>, [conditions_click])

  const placeholderButton = useMemo(() =>//placeholder
    <div
      style={{
        opacity: 0,
        cursor: 'default',
      }}

      className="comprar_btn"
    >
      Placeholder
    </div>, [])

  const date_tag_enabled = useMemo(() =>
    item?.endDate
    && (
      window.inIframe ?
        window.parameters.promotion_card_enable_date_tag_ecommerce
        :
        window.parameters.promotion_card_enable_date_tag
    )
    , [])

  const onde_encotrar_click = useCallback(useDebounceCallback((e) => {
    actions.app.CurrentOfferStoresModalPromotion(item)

    actions.app.currentOfferStoresModalOpened(true)

    promotionEcomEvent({
      event: 'offer_detail_find_store_button_click',
    }, item)
  }, 100), [item])

  const comprar_click = useCallback(useDebounceCallback(() => {
    promotionEcomEvent(
      {
        event: 'select_item',
      },
      item,
      {
        value: item.finalPrice || item.propertiesMap?.finalPrice,
        items: [{
          item_id: item.id || item.uuid,

          item_list_name: item?.propertiesMap ? "group-offer" : "regular-offer",
        }]
      }
    )
  }, 100), [item])

  return (
    <div className="main_card_container"
      {...style ? { style } : {}}

      data-promotion_id={item?.id || item?.uuid || ''}
      data-numberin_list={numberInList}
    >
      <div
        className={`main_card scale_in_animation ${descOverflowing ? (
          (
            item?.requiresRegisterComplete
            && !customer?.isRegisterComplete
          ) ? '' : 'resize_on_hover'
        )
          : ''}`}
        ref={mainCardRef}
      >
        <div className={`main_card_content ${window.inIframe ? 'inIframe' : ''}`}>

          <PromotionDiscountTag promotion={item} className="discount-top-tag-half">
            <CashbackSeal promotion={item} />
          </PromotionDiscountTag>

          <div className={`image_and_seals`}
            style={{
              ...cardHasSeals ? { marginRight: 0 } : {},
            }}>

            <PromotionImageWithFallback
              {...(window.parameters?.mainCardRedirectDisabled !== 'true' && (item?.url || item?.propertiesMap?.urlLink1)) && { href: `${item?.url || item?.propertiesMap?.urlLink1}${search}` } || {}}
              target="_blank"
              rel="noreferrer"
              className="product-image"
              promotion={item}
            />

            <PromotionSeals promotion={item} />
          </div>

          {
            date_tag_enabled ?
              <div className="time_tag primary_background text_primary_background">
                <ScheduleSVG />
                {'VÁLIDO ATÉ '}
                {format(parseDate(item?.endDate) as Date, 'dd/MM/yyyy')}
              </div>
              : <></>
          }

          <p
            className={`product-name ${window.inIframe ? 'inIframe' : ''} ${date_tag_enabled ? 'two_lines' : ''}`}
            title={current_promotion_sku?.name || item?.description || item?.propertiesMap?.description}
            ref={descRef}
          >
            {current_promotion_sku?.name || item?.description || item?.propertiesMap?.description}
          </p>

          <PromotionPrices promotion={item} className="prices_and_button">

            {
              !window.inIframe ?
                <>
                  {
                    window.currentTemplate.includes('promotions-wifi') ?
                      placeholderButton
                      :
                      (
                        window.parameters?.mainCardRedirectDisabled !== 'true'
                          && (item?.url || item?.propertiesMap?.urlLink1)
                          ?
                          <a
                            className="primary_background common_text_button text_primary_background common_button_border_radius comprar_btn"
                            //@ts-ignore
                            href={`${item?.url || item?.propertiesMap?.urlLink1}${search}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={comprar_click}
                          >
                            Comprar
                          </a>
                          :
                          (
                            item?.storeIds?.length
                              || item?.type == "PERSONALIZED"
                              ?
                              <div

                                className="primary_background common_text_button text_primary_background common_button_border_radius comprar_btn"

                                onClick={onde_encotrar_click}
                              >
                                Onde encontrar
                              </div>
                              :
                              placeholderButton
                          )
                      )
                  }

                  {offerConditionsButton}
                </>
                : <></>
            }
          </PromotionPrices>

          {
            window.inIframe ?
              <div className="ecommerce">

                <div className="cart_buttons" style={{
                  ...window.parameters.enable_offer_condition_in_iframe ? {
                    marginBottom: '4px'
                  } : {},
                }}>

                  <div
                    className={`delete ${(productInCart?.quantity || 0) > 0 ? '' : 'disabled'}`}

                    onMouseDown={(e) => {
                      e.preventDefault()
                      if ((productInCart?.quantity || 0) > 0) {
                        changeQuantity(-1)
                      }
                    }}>
                    <RemoveSVG />
                  </div>

                  <div className="quantity">
                    {
                      `${(productInCart?.quantity || 0) * (item.properties?.PRODUCT_ECOM_SALE_STEP || 1)}${item.properties?.PRODUCT_ECOM_SALE_STEP_UNIT || ''}`
                    }
                  </div>

                  <div
                    className={`add ${can_add_more ? '' : 'disabled'}`}

                    onMouseDown={(e) => {
                      e.preventDefault()
                      if (can_add_more) {
                        changeQuantity(1)
                      }
                    }}>
                    <AddSVG />
                  </div>
                </div>

                {
                  window.parameters.enable_offer_condition_in_iframe ? offerConditionsButton : <></>
                }

              </div>
              : ''
          }

          {
            item.maxPerCustomer
              && (
                window.inIframe ?
                  window.parameters.promotion_card_enable_limit_tag_ecommerce
                  :
                  window.parameters.promotion_card_enable_limit_tag
              )
              ?
              <div className="limit_tag">
                {
                  item.maxPerCustomer < 0 || item.maxPerCustomer == 10000
                    ?
                    'Oferta sem limite de uso'
                    :
                    `Limite: ${item.maxPerCustomer} unidades`
                }
              </div>
              : <></>
          }

        </div>

        {
          item?.requiresRegisterComplete
            && window.currentTemplate == 'portal-do-cliente'
            && !window.inIframe
            && !customer?.isRegisterComplete
            && !window.inIframe
            ?
            <QueryLink
              to={registrationRedirectLink || '/'}
              className="locked_card_link"

              onClick={(event) => {

                const place = (event.target as HTMLElement)?.closest('#recently-activated-promotions-modal') ? `signup_completion_modal_card_click` : `signup_completion_offer_card_click`

                const ss = sessionStorage.getItem(place) ?? ''

                const ctaText = (event.target as HTMLElement)?.closest('.locked_card_link')?.querySelector('.locked_subheader')?.textContent

                if (
                  place.includes('signup_completion_modal_card_click')
                  && ss != 'true'
                ) {

                  window.dataLayer.push({
                    event: place,
                    ctaText,
                  })

                  sessionStorage.setItem(place, 'true')

                } else if (!place.includes(item.id)) {

                  promotionEvent({
                    event: place,
                    ctaText,
                  }, item)

                  sessionStorage.setItem(place, `${ss}${ss ? ',' : ''}${item.id}`)
                }

                actions.app.openRegistrationModal(false)
                pdcStore.set.openRecentlyActivatedPromotionsModal(false)
              }}
            >
              <LockerSVG className="locker_icon align-self-center" />

              <div className="locked_header align-self-center">
                Complete e ganhe!
              </div>

              <div className="locked_subheader align-self-center">
                Complete seu cadastro e desbloqueie esta oferta!
              </div>

              <div className="locked_button align-self-center primary_background text_primary_background common_text_button">
                Desbloquear oferta
              </div>

            </QueryLink>
            : <></>
        }

        <PromotionTypeTag className="left_bookmark" promotion={item} />

      </div>
    </div>
  )
}
