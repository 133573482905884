import { FC, useMemo, useEffect, useState } from "react"

import {
  useHistory,
  useLocation,
} from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { Button, Modal } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

import './style.sass'

import {
  getNextRegStep,
  notificationsNotAvailable,
} from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import { pdcStore } from "@assets/store/pdcStore"

export const WebpushModal: FC = () => {

  const history = useHistory()

  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const { search } = useLocation()

  const {
    DeniedNotificationsModalState,
    showEmailSkippedPopup,
    showPhoneSkippedPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const customer = useTrackedStore().app.customer()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const WebpushModalOpened = pdcStore.use.WebpushModalOpened()

  const UserWantsPWA = pdcStore.use.UserWantsPWA()

  useEffect(() => {
    //check if Notifications functionality available in user's browser
    if (WebpushModalOpened && notificationsNotAvailable()) {

      console.log('[WebpushModal] notificationsNotAvailable')

      pdcStore.set.WebpushModalOpened(false)

      dispatch(setPropzMediaStateParameter({
        DeniedNotificationsModalState: {
          continue: true,
          opened: false
        }
      }))
    }
  }, [WebpushModalOpened])

  const popupOpened = useMemo(() => {
    //console.log('[WebpushModal] WebpushModalOpened, showEmailSkippedPopup, showPhoneSkippedPopup : ', WebpushModalOpened, showEmailSkippedPopup, showPhoneSkippedPopup)

    return WebpushModalOpened
      && !showEmailSkippedPopup
      && !showPhoneSkippedPopup
  }, [
    WebpushModalOpened,
    showEmailSkippedPopup,
    showPhoneSkippedPopup,
  ])

  useEffect(() => {
    if (popupOpened && !customer.webPushContactFlag) {
      window.dataLayer.push({
        event: pathname.includes('cadastro') ? 'push_notification_signup_modal_view' : 'push_notification_pwa_app_modal_view',
        pwaTrackingId: window.pwaTrackingId,
      })
    }
  }, [popupOpened])

  const checkPermission = async (permission: any, val: 0 | 1) => {
    switch (permission.state) {
      case 'granted':

        dispatch(setPropzMediaStateParameter({
          DeniedNotificationsModalState: {
            continue: true,
            opened: false
          }
        }))

        if (!customer?.webPushContactFlag) {

          window.dataLayer.push({
            event: pathname.includes('cadastro') ? 'push_notification_signup_modal_click' : 'push_notification_pwa_app_modal_click',
            pwaTrackingId: window.pwaTrackingId,
          })

          dispatch(updateCustomer({ webPushContactFlag: !!val }))

        } else {
          pdcStore.set.triggerUpdateFirebaseRegistration()
        }

        break
      default:

        console.log('[WebpushModal] permission default')

        dispatch(setPropzMediaStateParameter({
          DeniedNotificationsModalState: {
            continue: false,
            opened: true
          }
        }))

        window?.Notification?.requestPermission()
    }
  }

  const updateNotifications = (val: 0 | 1) => {

    pdcStore.set.WebpushModalOpened(false)

    if (val) {
      try {
        window.navigator?.permissions?.query({ name: 'notifications' })
          .then(permission => {

            console.log('[WebpushModal] updateNotifications permission : ', permission)

            checkPermission(permission, val)

            permission.onchange = () => {
              console.log('notification permission changed : ', permission)
              checkPermission(permission, val)
            }
          })
      } catch (e) {
        console.log('[WebpushModal] notification permission error : ', e)
        dispatch(setPropzMediaStateParameter({
          DeniedNotificationsModalState: {
            continue: true,
            opened: false
          }
        }))
      }

    } else {

      // PUSH access rejected event
      window.dataLayer.push({
        event: "pwa - decline_notification",
        pwaTrackingId: window.pwaTrackingId
      })

      dispatch(updateCustomer({ webPushContactFlag: false }))

      //!!!!continue reg or pwa flow
      dispatch(setPropzMediaStateParameter({
        DeniedNotificationsModalState: {
          continue: true,
          opened: false
        }
      }))
    }
  }

  //next step
  useEffect(() => {

    if (DeniedNotificationsModalState.continue) {

      if (pathname.includes("cadastro-aceite-webpush")) {
        history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)

      } else if (pathname.includes('webpush-pagina')) {
        history.push(`/${search}`)

      } else if (UserWantsPWA && window.PWAInstallEvent?.prompt) {

        window.PWAInstallEvent.prompt()

        window.PWAInstallEvent.userChoice
          .then(({ outcome }: { outcome: string }) => {

            console.log(`User response to the install prompt: ${outcome}`)

            window.dataLayer.push({
              event: outcome == "accepted" ? "pwa - access_granted" : "pwa - access_rejected",
              pwaTrackingId: window.pwaTrackingId
            })
          })
      }

      dispatch(setPropzMediaStateParameter({
        DeniedNotificationsModalState: {
          opened: false,
          continue: false
        }
      }))

    }

  }, [DeniedNotificationsModalState])

  useEffect(() => {

    if (UserWantsPWA && pathname != "/cadastro-aceite-webpush" && customer?.webPushContactFlag) {

      updateNotifications(1)

    }

  }, [UserWantsPWA])

  const regFlow = useMemo(() => pathname.includes("cadastro-") || pathname.includes("/webpush-pagina"), [pathname])

  return (
    <Modal
      actions={[
        <Button flat node="button" waves="light" className="primary_color_text"
          onClick={() => updateNotifications(0)}
        >
          {
            regFlow ?
              `Não quero receber${!isMobile ? ' as' : ''} ofertas exclusivas :(`
              :
              'Não quero receber as melhores ofertas'
          }
        </Button>,
        <Button flat node="button" waves="light" className="primary_color_background white-text big_button"
          onClick={() => {
            updateNotifications(1)
          }}
        >
          {
            regFlow ?
              `Quero receber${!isMobile ? ' notificações de' : ''} ofertas exclusivas`
              :
              'Quero a melhor experiência'
          }
        </Button>
      ]}

      header={
        regFlow ?
          'Quer ficar por dentro das nossas ofertas?'
          :
          'Ativar notificações'
      }
      open={popupOpened}

      id="web_push_modal"
      options={{
        dismissible: false,
        startingTop: "17%",
      }}
    >
      <p>
        {
          regFlow ?
            `Habilite notificações no seu navegador para receber ofertas selecionadas especialmente ${window.userIsFisicalPerson ? 'para' : ''} você!`
            :
            'Habilite notificações no seu navegador para uma melhor experiência no app'
        }
      </p>
    </Modal>
  )
}