import React, { FC, } from "react"

import empty_phone from "@assets/images/empty_phone.png"

import { useApp } from '@assets/hooks/useApp'

import './style.sass'
import { useComponentSize } from "@assets/hooks/useComponentSize"
import { useIntersectionObserver } from "usehooks-ts"
import { checkStorageAndStore } from "@assets/utils"

export const PromotionBarAppCTA: FC = () => {

  const {
    glob_programLogo,
  } = window.parameters

  const [_, action] = useApp('promotionbar')

  const [ref, width] = useComponentSize(true, 'width')

  const { ref: obsever_ref } = useIntersectionObserver({
    threshold: 0.9,
    onChange(isIntersecting) {
      if (isIntersecting && !checkStorageAndStore(sessionStorage, 'pwa_install_promotionbar_view', '1')) {
        window.dataLayer.push({
          event: 'pwa_install_promotionbar_view'
        })
      }
    },
  })

  return (
    <div
      className={`promotion_bar_app_cta`}
      onClick={action}
      ref={e => {
        obsever_ref(e)
        ref(e)
      }}
      style={{
        //@ts-ignore
        '--cta-width': `${width}px`,
      }}
    >

      <div className="image" style={{
        backgroundImage: `url(${empty_phone})`
      }} >
        <div className="logo" style={{
          backgroundImage: `url(${glob_programLogo})`
        }} />
      </div>

      <div className="text_content">
        <div className="header">
          É mais fácil pelo App
        </div>

        <div className="subheader">
          Instale o App e receba novas ofertas na palma da sua mão!
        </div>

        <div className="install_button primary_background text_primary_background">
          Instalar
        </div>
      </div>

    </div >
  );
};
