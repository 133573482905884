export const iOSVersion = () => {

  if (/iP(hone|ad)/.test(navigator.platform) || /iP(hone|ad)/.test(navigator.userAgent)) {

    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)

    //@ts-ignore
    return {
      major: parseInt(v[1], 10),
      minor: parseInt(v[2], 10),
      patch: parseInt(v[3] || 0, 10),
    }

  }
  return null
}