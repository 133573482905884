export const vipcommerce_redirect = () => {
  try {
    if (window.top) {
      if (window.parameters.vipcommerce_cart_url) {
        //omnitreinamento://carrinho - in app
        window.top.location.href = window.parameters.vipcommerce_cart_url
      } else {
        //in web
        window.top.location.pathname = '/checkout/carrinho'
      }
    }
  }
  catch (e) {
    console.log('vipcommerce redirect error : ', e)
  }
}