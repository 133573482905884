import { FC, useState, useEffect, } from "react"

import { Modal, Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import "./style.sass"

import { ReactComponent as CartSVG } from './cart_error.svg'
import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg'

import { vipcommerce_redirect } from "@/utils/internal functions/vipcommerce_redirect"

export const SendEcommerceCartErrorPopup: FC = () => {

  const dispatch = useAppDispatch()

  const {
    addedToEcommerceCart,
    addedSomeProductsToEcommerceCart,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const [redirect, setRedirect] = useState(addedSomeProductsToEcommerceCart)

  useEffect(() => {
    setRedirect(addedSomeProductsToEcommerceCart)
  }, [addedSomeProductsToEcommerceCart])

  return (
    <Modal
      id="SendEcommerceCartErrorPopup"
      open={addedToEcommerceCart === false}
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => {
          dispatch(setPropzMediaStateParameter({ addedToEcommerceCart: null }))

          //this only works this way
          let r
          setRedirect(redirect => { r = redirect; return redirect })

          if (r && window.top) {

            dispatch(setPropzMediaStateParameter({ addedSomeProductsToEcommerceCart: false }))

            vipcommerce_redirect()
          }
        }
      }}
    >
      <Button
        flat
        className="white close-button"

        onClick={() => dispatch(setPropzMediaStateParameter({ addedToEcommerceCart: null }))}
      >
        <CloseSVG />
      </Button>

      <CartSVG className="cart_image" />

      <div className="header">
        Ops! Alguns produtos não estão disponíveis
      </div>

      <div className="subheader">
        Alguns dos produtos selecionados não foram adicionados por não estarem disponíveis no momento. Verifique seu carrinho antes de finalizar a compra.
      </div>

    </Modal>
  );
};