import React, { useState, useLayoutEffect, useEffect, useCallback, } from 'react'

//@ts-ignore
import debounce from 'lodash.debounce'

/* 
displayed - condition of wherether the element is displayed
dimension - what size to get from component

returns [ref, specified dimension size]
*/

export function useComponentSize(displayed = true, dimension: 'height' | 'width' = 'height'): [(e: HTMLDivElement | null) => void, number] {

  const [element, set_element] = useState<HTMLElement | null>(null)
  const [element_size, set_element_size] = useState(0)

  const recalculatePosition = useCallback(debounce(() => {
    if (element) {
      set_element_size(element.getBoundingClientRect()[dimension])
    }
  }, 100), [element])

  useLayoutEffect(() => {
    if (element && displayed) {
      setTimeout(() =>
        set_element_size(element.getBoundingClientRect()[dimension]),
        200
      )

      window.addEventListener('resize', recalculatePosition, false)
    }
    return () => window.removeEventListener("resize", recalculatePosition)
  }, [element, displayed])

  return [(e: HTMLDivElement | null) => set_element(e), element_size]
}