import { pdcStore } from "@assets/store/pdcStore"
import { storeItem } from "@assets/utils"
import { isAndroid } from "@assets/utils/isAndroid"
import { isApple } from "@assets/utils/isApple"
import { useDebounceCallback } from "usehooks-ts"

/* returns the condition to show App CTA and action that should be made on click */
export function useApp(cta?: 'splashscreen' | 'banner' | 'headerbar' | 'promotionbar', callback?: () => void): [show: boolean, action: () => void] {

  const apple = isApple()
  const android = isAndroid()

  const action = useDebounceCallback(() => {

    window.dataLayer.push({
      event: `pwa_install_${cta ?? ''}_click`,
    })

    callback?.()

    if ((apple && window.parameters.glob_appIos) || (android && window.parameters.glob_appAndroid)) {

      const href = new URL((apple ? window.parameters.glob_appIos : window.parameters.glob_appAndroid) || "about:blank")

      href.searchParams.set('utm_source', 'pdc')

      href.searchParams.set('utm_medium', `app_${cta}`)

      href.searchParams.set('utm_campaign', `app_install_${cta}_cta`)

      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href,
      }).click()
    } else {
      pdcStore.set.WebpushModalOpened(true)

      pdcStore.set.UserWantsPWA(true)

      if (cta) {
        storeItem(sessionStorage, 'InstallPWAFromCTA', cta)
      }
    }
  }, 50)

  return [
    !window.parameters.app_ctas_disabled
    && !window.isApp
    && (
      (apple && window.parameters.glob_appIos)
      || (android && window.parameters.glob_appAndroid)
      || window.PWAInstallEvent
    ),
    action
  ]
}